import {ref} from 'vue';
import {defineStore} from 'pinia';
import {setDefaultMenuTabs} from "@/utils/common";
import {useRouter} from "vue-router";
import dbUtils from '@/utils/strotage.js'
import setting from "@/setting";

const indexRoute = '/'

// 去重函数，根据 key 去重
function unique(arr, key) {
  if (!arr) return arr;
  if (key === undefined) return [...new Set(arr)];
  const map = {
    'string': e => e[key],
    'function': e => key(e),
  };
  const fn = map[typeof key];
  const obj = arr.reduce((o, e) => ((o[fn(e)] = e), o), {});
  return Object.values(obj);
}

// 根据路径从数组中移除对应的项
function removeFromArrayByPath(arr, pathToDelete) {
  const indexToDelete = arr.findIndex(item => item.path === pathToDelete);
  if (indexToDelete !== -1) {
    arr.splice(indexToDelete, 1);
  }
}

export const useMenuStore = defineStore('menu', () => {

  const router = useRouter();

  let openKeys = ref([]);   // 展开的菜单组
  let currentRoute = ref(indexRoute);   // 当前路由path
  let menuBadge = ref({});   // 菜单角标对象

  let tabsList = ref([]);

  function currentRouteSet(path) {
    currentRoute.value = path;
  }

  function menuBadgeSet(object) {
    menuBadge.value = object;
  }

  function menuBadgeKeyValueSet(key, val) {
    menuBadge.value[key] = val;
  }

  function openKeysSet(path) {
    if (Array.isArray(path)) {
      openKeys.value = path;
    } else {
      const index = openKeys.value.findIndex(v => v === path)
      if (index > -1) {
        openKeys.value.splice(index, 1);
      } else {
        openKeys.value.push(path);
      }
    }
  }

  function routeSet(path) {
    if (currentRoute.value === path) return;
    currentRoute.value = path;
    openKeysSet(path);
    router.push(path)
  }

  // 初始化 tabs
  function initTabs() {
    tabsList.value = []
    const storedTabs = dbUtils.get('tabs');
    let tabs = []
    if (storedTabs && storedTabs.length) {
      tabs = storedTabs
    } else {
      tabs = setDefaultMenuTabs();
    }
    tabsList.value = tabs;
    dbUtils.set('tabs', tabs);
  }

  initTabs()

  function resetTabs() {
    tabsList.value = []
    tabsList.value = setDefaultMenuTabs();
  }

  // 添加新的 tab
  function addTabs(item) {
    tabsList.value.push(item);
    tabsList.value = unique(tabsList.value, 'path');
    dbUtils.set('tabs', tabsList.value);
  }

  // 移除指定的 tab
  function removeTabs(key) {
    const tabIndex = tabsList.value.findIndex(item => item.path === key);
    const currentIndex = tabsList.value.findIndex(item => item.path === currentRoute.value);

    // 先进行删除
    removeFromArrayByPath(tabsList.value, key);
    dbUtils.set('tabs', tabsList.value);
    removeRouteStorage(key)

    // 再设置激活项
    if (tabIndex >= 0) {
      // 后一个 下标
      const tabNextIndex = Math.min(tabIndex + 1, tabsList.value.length - 1);
      // 前一个 下标
      const tabPreIndex = Math.max(tabIndex - 1, 0);
      const tabNextItem = tabsList.value[tabNextIndex];
      const tabPreIndexItem = tabsList.value[tabPreIndex];
      // 选中项和将要删除的项不是同一个则不改变激活项
      if (currentIndex !== tabIndex) {
        return routeSet(currentRoute.value);
      }
      // 如果前后都无法找到则设置激活项为第一个 （激活首页）
      routeSet(tabNextItem ? tabNextItem.path : (tabPreIndexItem ? tabPreIndexItem.path : tabsList.value[0].path));
    } else {
      routeSet(tabsList.value[0].path);
    }
  }

  // 清除左侧或右侧的标签
  function removeRightOrLeftTabs(direction) {
    // 获取当前标签的索引
    const currentIndex = tabsList.value.findIndex(item => item.path === currentRoute.value);

    if (currentIndex === -1) return;

    if (direction === 'left') {
      // 清除左侧标签，排除path等于首页的标签，同时排除首页
      tabsList.value = tabsList.value.filter((item, index) => {
        return index >= currentIndex || (index === 0 && item.path === indexRoute);
      });
    } else if (direction === 'right') {
      // 清除右侧标签，排除path等于首页的标签
      tabsList.value = tabsList.value.filter((item, index) => index <= currentIndex || (index === 0 && item.path === indexRoute));
    } else if (direction === 'other') {
      tabsList.value = tabsList.value.filter(item => item.path === currentRoute.value || item.path === indexRoute);
    } else if (direction === 'all') {
      tabsList.value = tabsList.value.filter(item => item.path === indexRoute);
      routeSet(indexRoute);
      dbUtils.set('tabs', tabsList.value);
      removeRouteStorage()
      return;
    }

    routeSet(currentRoute.value);
    dbUtils.set('tabs', tabsList.value);
    removeRouteStorage()
  }

  // 删除路由缓存
  function removeRouteStorage(key = '') {
    let consignOrderToyGoodsNeedStoreData = dbUtils.get(setting.consignOrderToyGoodsNeedStoreKey) || []
    if (key) {
      if (/toy-goods-need/.test(key)) {
        // 获取ID
        const parts = key.split('/')
        const id = parts[parts.length - 1];
        const index = consignOrderToyGoodsNeedStoreData.findIndex(item => item.key == id)
        consignOrderToyGoodsNeedStoreData.splice(index, 1)
        if (consignOrderToyGoodsNeedStoreData.length === 0) {
          dbUtils.remove(setting.consignOrderToyGoodsNeedStoreKey)
        } else {
          dbUtils.set(setting.consignOrderToyGoodsNeedStoreKey, consignOrderToyGoodsNeedStoreData)
        }
      }
      if (/flash-sale-set/.test(key)) {
        dbUtils.remove(setting.flashSaleTabQueryStoreKey)
      }
    } else {
      const needTabs = tabsList.value.filter(item => item.name === 'toy-goods-need');
      if (needTabs.length === 0 && consignOrderToyGoodsNeedStoreData.length > 0) {
        dbUtils.remove(setting.consignOrderToyGoodsNeedStoreKey)
      }
      const flashSaleTabs = tabsList.value.filter(item => item.name === 'flash-sale-set');
      if (flashSaleTabs.length === 0) {
        dbUtils.remove(setting.flashSaleTabQueryStoreKey)
      }
    }
  }

  return {
    openKeys,
    currentRoute,
    menuBadge,
    tabsList,
    openKeysSet,
    currentRouteSet,
    menuBadgeSet,
    menuBadgeKeyValueSet,
    removeRightOrLeftTabs,
    removeTabs,
    routeSet,
    addTabs,
    resetTabs,
  };
});
