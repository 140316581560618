import {defineStore} from 'pinia';
import {useRouter} from 'vue-router';
import dbUtils from '@/utils/strotage.js'
import {authLogin, authLogout, getUserInfo} from "@/api/user";
import {AntdConfirm, AntdMessage} from "@/utils/toast";
import setting from "@/setting";
import {ref} from "vue";
import {getAllOptions} from "@/api/common";
import {setAsyncRoutes} from "@/router";
import {useMenuStore} from "@/stores/menu";

export const useAuthStore = defineStore('auth', () => {
  let router = useRouter()
  const user = ref(null)
  const isSpecialUser = ref(false)
  const options = ref(null)

  const menuStore = useMenuStore()

  async function logout() {
    AntdConfirm('你确定要退出登录吗?').then(ok => {
      if (ok) {
        // 执行退出登录逻辑，例如清除用户凭证和重置用户状态等
        authLogout().then(res => {
          if (res.code === 200) {
            user.value = null
            isSpecialUser.value = false
            options.value = null
            // 清除所有缓存
            dbUtils.clear()
            menuStore.resetTabs()
            // 导航到登录页或其他适当的页面
            router.replace('/login');
          } else {
            AntdMessage.error(res.msg)
          }
        })
      }
    })
  }

  async function login(value) {
    // 执行登录逻辑
    // ...
    return new Promise((resolve, reject) => {
      authLogin(value).then(res => {
        if (res.code === 200) {
          user.value = null
          isSpecialUser.value = false
          dbUtils.clear()
          dbUtils.set(setting.tokenKey, res.data.token_type + ' ' + res.data.access_token);
          // 重定向
          const routeQuery = router.currentRoute.value.query || {}
          router.replace(routeQuery && routeQuery.redirect || '/');
          resolve()
        } else {
          reject(res)
          AntdMessage.error(res.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
  async function getUserDetail() {
    return new Promise((resolve, reject) => {
      getUserInfo().then(res => {
        if (res.code === 200) {
          // 存储用户信息
          user.value = res.data
          // 动态设置异步路由
          setAsyncRoutes()

          // 管理员角色
          let href = window.location.href
          isSpecialUser.value = href && href.indexOf('key=JB123') !== -1 ? 1 : 0;

          resolve()

        } else {
          reject(res)
          AntdMessage.error(res.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }
  async function getOptionList() {
    // 执行登录逻辑
    // ...
    return new Promise((resolve, reject) => {
      getAllOptions().then(res => {
        if (res.code === 200) {
          options.value = res.data
          resolve()
        } else {
          reject(res)
          AntdMessage.error(res.msg)
        }
      }).catch(err => {
        reject(err)
      })
    })
  }

  return {
    user,
    isSpecialUser,
    options,
    logout,
    login,
    getUserDetail,
    getOptionList,
  };
})
