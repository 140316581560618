import dbUtils from "@/utils/strotage";
import setting from "@/setting";
import dayjs from "dayjs";
import {md5} from "@/utils/md5";
import axios from "axios";
import {useMenuStore} from "@/stores/menu";

/**
 * 16进制颜色转换透明度后的16进制颜色值
 * @param {string} color 原始颜色 #FFFFFF
 * @param {number} opacity 颜色透明度 10 20
 * @returns {string} 转换后的颜色值
 */
export const adjustColorOpacity = function (color, opacity) {
  // 移除 # 号（如果有）
  color = color.replace("#", "");

  // 将十六进制颜色值转换为 RGB 值
  let r = parseInt(color.substring(0, 2), 16);
  let g = parseInt(color.substring(2, 4), 16);
  let b = parseInt(color.substring(4, 6), 16);

  // 将透明度转换为 0 到 1 之间的值
  opacity = opacity / 100;

  // 计算插值后的颜色值
  let interpolatedR = Math.round((1 - opacity) * 255 + opacity * r);
  let interpolatedG = Math.round((1 - opacity) * 255 + opacity * g);
  let interpolatedB = Math.round((1 - opacity) * 255 + opacity * b);

  // 将插值后的 RGB 值转换回十六进制颜色值
  let result = "#" + (interpolatedR < 16 ? "0" : "") + interpolatedR.toString(16);
  result += (interpolatedG < 16 ? "0" : "") + interpolatedG.toString(16);
  result += (interpolatedB < 16 ? "0" : "") + interpolatedB.toString(16);

  return result;
}

/**
 * 判断空对象
 * @param {Object} obj 原始对象
 * @returns {Boolean} 返回true/false
 */
export const isEmptyObject = function (obj) {
  return JSON.stringify(obj) === '{}';
}

/**
 * 替换字符串中间的数字为指定字符
 * @param {string} str 原始字符串
 * @param {string} replaceChar 替换的字符，默认为'*'
 * @param {number} numDigits 保留的数字位数，默认为1
 * @returns {string} 替换后的字符串
 */
export const replaceMiddleDigits = (str, replaceChar = '*', numDigits = 1) => {
  if (str.length < numDigits + 2) {
    return str;
  }
  const firstPart = str.substring(0, numDigits);
  const lastPart = str.substring(str.length - numDigits);
  const middlePart = replaceChar.repeat(str.length - numDigits * 2);

  return firstPart + middlePart + lastPart;
}

// 判断是否数字
export function isNumber(str) {
  let n = parseInt(str + '')
  return !isNaN(n);
}

export function getToken() {
  return dbUtils.get(setting.tokenKey) || ''
}

export function packageStatusColor(val) {
  let color = '#909399'
  if (val) {
    if (val.includes('全新')) {
      color = '#67c23a'
    } else if (val.includes('拆盒未拆袋') || val.includes('轻微')) {
      color = '#e6a23c'
    } else if (val.includes('已拆袋') || val.includes('中度')) {
      color = '#f56c6c'
    }
  }
  return color;
}

/**
 * 格式化打折显示
 * @param val
 * @param unit
 */
export function formatDiscount(val) {
  let str = ''
  if (!val) val = 0;
  if (val <= 0 || val >= 100) {
    str = '不打折'
  } else {
    let arr = ['', '一', '二', '三', '四', '五', '六', '七', '八', '九']
    val = parseInt(val)
    str += '打'
    if (val > 10) {
      str += arr[parseInt(val / 10)]
    } else {
      str += '零'
    }
    str += arr[parseInt(val % 10)]
    str += '折'
  }
  return str;
}

// 格式化为浮点型数据
export function formatToFloat(str, min = 0, max = 999999999, digits = 2) {
  let num = parseFloat(str + '')
  if (isNaN(num)) {
    num = parseFloat(0 + '').toFixed(digits)
  } else {
    if (num < min) {
      num = min
    } else if (num > max) {
      num = max
    }
    num = parseFloat(num + '').toFixed(digits)
  }
  return parseFloat(num)
}

/**
 * 格式化金额并格式化千分位
 * @param val
 * @param num
 */
export function formatMoney(val, num = 2) {
  if (!val) val = 0;
  val = Number(val)
  // 小数整数分离
  let valArr = val.toString().split('.')
  // 整数部分千分位
  let price = valArr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (valArr[1]) {
    let decimal = val.toFixed(num).toString().split('.')
    price = price.toString() + '.' + decimal[1]
  }
  return price;
}

export function getAllDuration() {
  return [dayjs('2020-01-01 00:00:00'), dayjs().endOf('year')];
}

export function getDefaultValue() {
  return [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')];
}

/**
 * 部分预设时间范围
 * @returns {dayjs.Dayjs[][]}
 */
export function getDateRangeDuration() {
  const yesterday = dayjs().add(-1, 'd')
  const yesterdayBefore = dayjs().add(-2, 'd')
  const endTime = yesterday.hour(23).minute(59).second(59);
  // 今日
  const todayRange = [dayjs().startOf('date'), dayjs().endOf('date')];
  // 昨日
  const yesterdayRange = [yesterday.hour(0).minute(0).second(0), yesterday.hour(23).minute(59).second(59)];
  // 前天
  const yesterdayBeforeRange = [yesterdayBefore.hour(0).minute(0).second(0), yesterdayBefore.hour(23).minute(59).second(59)];
  // 本周
  const weekRange = [dayjs().startOf('week'), dayjs().endOf('week')];
  // 本月
  const monthRange = [dayjs().startOf('month'), dayjs().endOf('month')];
  // 本年
  const yearRange = [dayjs().startOf('year'), dayjs().endOf('year')];
  // 最近一周
  const lastWeekRange = [dayjs().add(-1, 'w').hour(0).minute(0).second(0), endTime];
  // 最近一个月
  const lastMonthRange = [dayjs().add(-1, 'M').add(1, 'd').hour(0).minute(0).second(0), endTime];
  // 最近三个月
  const lastThreeMonthsRange = [dayjs().add(-3, 'M').add(1, 'd').hour(0).minute(0).second(0), endTime];
  // 最近一年
  const lastYearRange = [dayjs().add(-1, 'y').add(1, 'd').hour(0).minute(0).second(0), endTime];
  // 最近三年
  const lastThreeYearsRange = [dayjs().add(-3, 'y').add(1, 'd').hour(0).minute(0).second(0), endTime];
  return {
    todayRange,
    yesterdayRange,
    yesterdayBeforeRange,
    weekRange,
    monthRange,
    yearRange,
    lastWeekRange,
    lastMonthRange,
    lastThreeMonthsRange,
    lastYearRange,
    lastThreeYearsRange
  };
}

// 默认查询的时间 - 本月
export function getDefaultSearchDate() {
  const {monthRange} = getDateRangeDuration()
  return monthRange;
}

// 获取默认时间选择键
export function getDateTimeRangePresets() {
  const {
    todayRange,
    yesterdayRange,
    weekRange,
    monthRange,
    yearRange,
    lastWeekRange,
    lastMonthRange,
    lastThreeMonthsRange,
    lastYearRange,
    lastThreeYearsRange
  } = getDateRangeDuration()
  return [
    {
      label: '今天',
      value: todayRange,
    },
    {
      label: '昨天',
      value: yesterdayRange,
    },
    {
      label: '本周',
      value: weekRange,
    },
    {
      label: '本月',
      value: monthRange,
    },
    {
      label: '本年',
      value: yearRange,
    },
    {
      label: '最近一周',
      value: lastWeekRange,
    },
    {
      label: '最近一月',
      value: lastMonthRange,
    },
    {
      label: '最近三月',
      value: lastThreeMonthsRange,
    },
    {
      label: '最近一年',
      value: lastYearRange,
    },
    {
      label: '最近三年',
      value: lastThreeYearsRange,
    },
  ]
}

/* 格式化日期 */
export function formatDateToString(date, type = 'datetime') {
  var yy = date.getFullYear() + ''; //年
  var mm = date.getMonth() + 1 + ''; //月
  var dd = date.getDate() + ''; //日
  var hh = date.getHours() + ''; //时
  var ii = date.getMinutes() + ''; //分
  var ss = date.getSeconds() + ''; //秒
  var str = ''
  if ('date' === type) {
    str = yy + "-" + mm.padStart(2, '0') + "-" + dd.padStart(2, '0')
  } else if ('time' === type) {
    str = hh.padStart(2, '0') + ":" + ii.padStart(2, '0') + ":" + ss.padStart(2, '0')
  } else {
    str = yy + "-" + mm.padStart(2, '0') + "-" + dd.padStart(2, '0')
    str += " "
    str += hh.padStart(2, '0') + ":" + ii.padStart(2, '0') + ":" + ss.padStart(2, '0')
  }
  return str
}

/**
 * 返回时间范围时间戳
 * @param dateStrings
 * @returns {*[]}
 */
export function formatTimestampRangeDate(dateStrings) {
  let startTime = null, endTime = null;
  if (dateStrings && dateStrings.length > 0) {
    let [index0, index1] = dateStrings
    if (index0 && index1) {
      startTime = dayjs(`${index0} 00:00:00`).unix()
      endTime = dayjs(`${index1} 23:59:59`).unix()
    }
  }
  return [startTime, endTime];
}

/**
 * 格式化时间日期范围
 * @param rang
 * @returns {*[]}
 */
export function formatDateTimeRange(rang) {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  let startTime = null, endTime = null;
  if (rang && rang.length > 0) {
    let [index0, index1] = rang
    if (index0 && index1) {
      startTime = dayjs(index0).format(dateFormat)
      endTime = dayjs(index1).format(dateFormat)
    }
  }
  return [startTime, endTime];
}

export function formatTimeRange(rang) {
  const dateFormat = 'HH:mm:ss'
  let startTime = null, endTime = null;
  if (rang && rang.length > 0) {
    let [index0, index1] = rang
    if (index0 && index1) {
      startTime = dayjs(index0).format(dateFormat)
      endTime = dayjs(index1).format(dateFormat)
    }
  }
  return [startTime, endTime];
}

export function formatSecondToStr(second) {
  let str = ''
  if (second < 60) {
    str = second + '秒'
  } else if (second < 3600) {
    str = parseInt((second / 60)) + '分'
    if (second % 60) {
      str += parseInt((second % 60)) + '秒'
    }
  } else if (second < 86400) {
    str = parseInt(second / 3600) + '时'
    if (parseInt(((second % 3600) / 60))) {
      str += (parseInt(((second % 3600) / 60)) + '').padStart(2, '0') + '分'
    }
  } else {
    str = parseInt(second / 86400) + '天'
    if (parseInt(((second % 86400) / 3600))) {
      str += (parseInt(((second % 86400) / 3600)) + '').padStart(2, '0') + '时'
    }
  }
  return str
}

/**
 * 秒数换成成时间格式
 * @param seconds
 * @param format
 * @returns {string}
 */
export function parseSecondsFormatTime(seconds, format='m:s') {

  let [hour, minute, second] = [0, 0, 0];

  if (format === 'h:m:s') {

    hour = Math.floor(seconds/3600)
    minute = Math.floor(seconds/60) % 60
    second = seconds % 60

    hour = hour < 10 ? '0' + hour : hour;
    minute = minute < 10 ? '0' + minute : minute;
    second = second < 10 ? '0' + second : second;

    return `${hour}:${minute}:${second}`
  }

  minute = Math.floor(seconds / 60);
  second = Math.floor(seconds) - minute * 60;

  minute = minute < 10 ? '0' + minute : minute;
  second = second < 10 ? '0' + second : second;

  return `${minute}:${second}`

}


export function getStatusColor(options, status) {
  let color = '#f1f1f1'
  const index = options.findIndex(v => v.value === status)
  if (index > -1) {
    color = options[index].color
  }
  return color;
}

/**
 * 深拷贝
 * @param data
 */
export function cloneDeep(data) {
  return JSON.parse(JSON.stringify(data));
}

/**
 * 树数据展平
 * @param list
 * @param dataSource
 */
export function flatten(list, dataSource) {
  list.forEach(item => {
    dataSource.push(item)
    if (item.children) flatten(item.children, dataSource)
  })
  return dataSource
}

export function arrayUnique(arr, key) {
  const res = new Map()
  return arr.filter((item) => !res.has(item[key]) && res.set(item[key], 1))
}

export function getSignDataArr() {
  let appid = '5c731c8c4ee0f43b4b605d534f888603'
  let appKey = 'fd1c6f21943b1ef71aa19f7fd9c4860f'
  let signData = {
    appid: appid,
    client: 'browser',
    sign: '',
    sign_version: 'v1',
    timestamp: Math.floor((new Date()).getTime() / 1000),
  }
  // 生成签名
  let arr = []
  for (let key in signData) {
    if (key !== 'sign') {
      arr.push(key + '=' + signData[key])
    }
  }
  arr.push('key=' + appKey)
  signData.sign = md5(arr.join('&'))

  return [
    {key: 'X-Request-Appid', value: signData.appid},
    {key: 'X-Request-Client', value: signData.client},
    {key: 'X-Request-Sign', value: signData.sign},
    {key: 'X-Request-Sign-Version', value: signData.sign_version},
    {key: 'X-Request-Timestamp', value: signData.timestamp},
  ]
}

// 导出Excel
export function exportExcel(data, filename, sheetName) {
  const XLSX = require("xlsx");
  let wb = XLSX.utils.book_new();
  let ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, filename);
}

// 下载blob二进制文件
export function downloadFile(fileUrl, filename = '') {
  if (!filename) {
    let index1 = fileUrl.lastIndexOf('/')
    let index2 = fileUrl.lastIndexOf('?')
    if (index2 === -1) index2 = fileUrl.length
    filename = fileUrl.substring(index1 + 1, index2)
  }
  const url = window.URL.createObjectURL(new Blob([fileUrl]));
  axios.get(fileUrl, {responseType: 'blob'}).then((res) => {
    const blob = new Blob([res.data]);
    if (window.navigator.msSaveBlob) {
      // 兼容 IE，使用 msSaveBlob 方法进行下载
      window.navigator.msSaveBlob(blob, decodeURIComponent(filename));
    } else {
      // 创建一个 <a> 元素
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', decodeURIComponent(filename));
      // 模拟点击下载
      link.click();
      // 清理 URL 和 <a> 元素
      link.remove();
      window.URL.revokeObjectURL(url);
    }
  }).catch(() => {
    window.open(fileUrl)
  });
}

export function checkEnv() {
  let env = 'prod'
  if (process.env.VUE_APP_BASE_API.indexOf('test') !== -1) {
    env = 'test'
  } else if (process.env.VUE_APP_BASE_API.indexOf('dev') !== -1 || process.env.VUE_APP_BASE_API.indexOf('127.0.0.1') !== -1 || process.env.VUE_APP_BASE_API.indexOf('0.0.0.0') !== -1 || process.env.VUE_APP_BASE_API.indexOf('localhost') !== -1) {
    env = 'dev'
  }
  return env
}

//获取随机字符串
export function generateRandomLetter(len = 1) {
  let alphabet = 'ABCDECGHIJKLMNOPQRSTUVWXYZ';
  let pre = '';
  let randomIndex = 0
  for(let i = 0; i < len; i++){
    randomIndex = Math.floor(Math.random() * alphabet.length);
    pre += alphabet[randomIndex]
  }
  return pre;
}

// 设置导出的URL参数字符串
export function setUrlQueryString(param) {
  let arr = []
  for (let key in param) {
    let value = param[key] || '';
    if (key === 'sort_status') {
      value = param[key].replace('ing', '')+'ing'
    }
    arr.push(key + "=" + value)
  }
  return "?" + arr.join("&")  + '&token=' + getToken()
}

// 设置默认选中菜单
export function setDefaultMenuTabs() {
  return [
    {
      meta: {title: '首页'},
      name: 'index',
      path: '/'
    }
  ]
}

export function setDynamicRoutes() {
  return [
    {
      path: '/consign-order-detail/:id',
      name: 'consign-order-detail',
      meta: {
        cache: true,
        title: '寄收订单详情',
        requiresAuth: true,
      },
      component: () => import('@/views/pages/consign-order-detail'),
      hidden: true
    },
    {
      path: '/purchase-order-detail/:id',
      name: 'purchase-order-detail',
      meta: {
        cache: true,
        title: '售出订单详情',
        requiresAuth: true,
      },
      component: () => import('@/views/pages/purchase-order-detail'),
      hidden: true
    },
    {
      path: '/auction-session-edit/:id',
      name: 'auction-session-edit',
      meta: {
        cache: true,
        title: '拍卖场次',
        requiresAuth: true,
      },
      component: () => import('@/views/pages/auction-session-edit'),
      hidden: true
    },
    {
      path: '/flash-sale-set',
      name: 'flash-sale-set',
      meta: {
        cache: true,
        title: '设置限时抢购',
        requiresAuth: true,
      },
      component: () => import('@/views/pages/flash-sale'),
      hidden: true
    },
    {
      path: '/toy-goods-need/:id',
      name: 'toy-goods-need',
      meta: {
        cache: true,
        title: '款式求购',
        requiresAuth: true,
      },
      component: () => import('@/views/pages/toy-goods-need'),
      hidden: true
    },
    {
      path: '/toy-goods-sales/',
      name: 'toy-goods-sales',
      meta: {
        cache: true,
        title: '代理销售-新增商品',
        requiresAuth: true,
      },
      component: () => import('@/views/pages/toy-goods-member-sales'),
      hidden: true
    },
  ]
}

export function navigateToOrderDetail(id, path) {
  const menuStore = useMenuStore()
  menuStore.routeSet(`/${path}/${id}`)
}